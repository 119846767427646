.people
    &__wrapper
        display: grid
        grid-template-columns: repeat(auto-fit, 400px)
        justify-content: space-around
        column-gap: 30px
    &__card
        width: 400px        
        display: flex
        flex-direction: row
        margin-bottom: 20px
    &__img
        width: 200px
        height: 300px
        object-fit: cover
    &__text
        font-size: 20px
        margin-top: 20px
        margin-left: 20px
        span
            font-weight: bold

@media (max-width: 479px)
    .people
        &__wrapper
            grid-template-columns: 300px
        &__card
            width: 300px
        &__img
            width: 150px
            height: 150px
        &__text
            font-size: 18px