.nav-link
    color: white 
    text-decoration: none 
    font-size: 24px
    font-weight: bold
    &:hover, &:visited, &:link, &:active
        color: white


@media ( max-width: 767px )
    .buttons
        display: flex
        flex-direction: column
        margin-top: 15px
    .nav-btn
        margin-bottom: 5px
        border: none
        text-align: left